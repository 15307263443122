import axios from 'axios';
const base_url = () => {
  // return 'http://localhost:3002'; // local environment
  // 'http://wo-dev.quadsealpro.com:3002'; // dev environment
  // 'http://wo.quadsealpro.com'; // live environment => http://wo.quadsealpro.com:3001
  return 'https://wo-uat.quadsealpro.com:3001'; // UAT environment
};

 /**
         * <h5>Summary of fetchClient: </h5>
         * this function base urls, adding authentication header to axios
         * <ol>
         *      <li> baseURL : Defines base url of the server </li>
         *      <li> instance :  Create axios instance to access the apis with token in "config" header </li>
         * </ol>
         * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
         * <h6>Last updated by: <small>30/09/2019</small> </h6>
         * <h6>Reason for modify: <small>To create doc for Axios Instance </small> </h6>
*/
const fetchClient = () => {
  const defaultOptions = {
    //baseURL: 'http://localhost:3002', // local environment
    // baseURL: 'http://wo-dev.quadsealpro.com:3002', // dev environment
    // baseURL: 'http://wo.quadsealpro.com', // live environment => http://wo.quadsealpro.com:3001
    // baseURL: 'https://wo.quadsealpro.com:5001', // live environment => http => https
    baseURL: 'https://wo-uat.quadsealpro.com:3001', // UAT environment
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);
  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
    return config;
  });

  instance.interceptors.response.use(response => {
    return response;
  },
    error => {
      if (error.response && error.response.status === 401 || error.response.status === 500 || error.response.status === 504) {
        localStorage.removeItem('id_token');
        localStorage.removeItem('token');
        localStorage.removeItem('window_time');
        localStorage.removeItem('location');
        localStorage.removeItem('loggedInID');
        window.location.href = "/login";
      }
      else {
        return Promise.reject(error);
      }
    });

    console.log('instance', instance)
  return instance;
};

export { base_url };

export default fetchClient();